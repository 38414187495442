type _InviteBody = {
    client: string,
    vacancy: string,
    startTime: number,
    platform: number,
    location: string,
    description: string,
    message?: string,
    type: number
};

export const useApiResponses = () => {
    return {
        /**
         * Создать отклик от лица текущего пользователя
         * @param id
         */
        add: (id: string) => {
            return mainFetch('responses', {
                method: 'POST',
                body: { vacancy: id }
            })
        },
        invite: (body: _InviteBody, isForce: boolean = false) => {
            body = {
                isInvite: true,
                force: isForce,
                ...body
            };

            return mainFetch('responses', {
                method: 'POST',
                body: body
            });
        },
        cancel: (id: string) => {
            return mainFetch(`/responses/${id}`, {
                method: 'PUT',
                body: {
                    status: 8,
                    isArchive: true
                }
            });
        },
    };
}
